const modals = document.querySelectorAll("[data-modal]");

modals.forEach((modal) => {
  modal.addEventListener("click", () => {
    console.dir(modal.attributes["data-modal"].value);
    const target = document.querySelector(
      `#${modal.attributes["data-modal"].value}`
    );
    target.classList.add("active");

    const close = target.querySelectorAll("[data-modal-close]");
    close.forEach((item) => {
      item.addEventListener("click", () => {
        target.classList.remove("active");
      });
    });
  });
});
